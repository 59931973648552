<template>
	<client-only>
		<div class="checkout-container"></div>
	</client-only>
</template>

<script setup lang="ts">
import { initializePaddle } from '@paddle/paddle-js'

const { $csrfFetch } = useNuxtApp()
const runtimeConfig = useRuntimeConfig()
const store = useConsoleStore()
const user = useUser()

const props = defineProps<{
	plan?: string,
	transaction?: string,
}>()
const emit = defineEmits([ 'close' ])

const billing_selector_hide_cookie = useCookie('billing_selector_closed', {
	path: '/',
})
billing_selector_hide_cookie.value = billing_selector_hide_cookie.value || null

onMounted(async () =>
{
	if (store.organization._id)
		return await startCheckout()
	
	if (store.project.organization)
		return await startCheckout()
	
	console.error('Organization not selected')
	return
})

async function startCheckout()
{
	const paddle = await initializePaddle({
		environment: process.env.NODE_ENV == 'development' ? 'sandbox' : 'production',
		token: runtimeConfig.public.paddleClientSideToken as string,
		pwCustomer: {
			email: user.value?.email || '',
		},
		eventCallback: function(data) {
			if (data.name == 'checkout.completed')
			{
				billing_selector_hide_cookie.value = 'true'
				setTimeout(() => { window.location.reload() }, 1000)
			}
			else if (data.name == 'checkout.closed')
			{
				emit('close')
			}
		}
	})
	
	let items = undefined
	if (props.plan)
	{
		let price_id = runtimeConfig.public.paddleMlPlanFreePriceId as string
		if (props.plan == 'basic-monthly')
			price_id = runtimeConfig.public.paddleMlPlanBasicMonthlyPriceId as string
		else if (props.plan == 'basic-annually')
			price_id = runtimeConfig.public.paddleMlPlanBasicAnnuallyPriceId as string
		else if (props.plan == 'standard-monthly')
			price_id = runtimeConfig.public.paddleMlPlanStandardMonthlyPriceId as string
		else if (props.plan == 'standard-annually')
			price_id = runtimeConfig.public.paddleMlPlanStandardAnnuallyPriceId as string
		
		items = [{ priceId: price_id, quantity: 1 }]
	}
	
	let transaction_id: string | undefined = undefined
	if (props.transaction)
	{
		transaction_id = props.transaction as string
	}
	
	let items_or_transaction_id: any = {}
	if (items)
 		items_or_transaction_id = { items: items }
	else
		items_or_transaction_id = { transactionId: transaction_id }
	
	paddle?.Checkout.open({
		...items_or_transaction_id,
		customer: {
			email: user.value?.email || '',
			// business: {
			// 	name: store.organization.name as string,
			// },
		},
		settings: {
			// displayMode: 'inline',
			// frameTarget: "checkout-container",
			// frameInitialHeight: 450,
			showAddDiscounts: false,
			allowLogout: false,
			// successUrl: 'https://' + window.location.host + '/org-' + store.organization._id + '/billing/',
		},
		customData: {
			organization: store.organization._id || store.project?.organization || '',
			user: (user.value?.id || '') as string,
		},
	})
}
</script>

<style lang="scss" scoped>
</style>