<template>
<div class="console-section" :class="{ loading: loading }">
	<div class="pricing" v-if="!selected_plan">
		<div class="page-description">
			<p>Almeta&nbsp;ML offers usage-based pricing. Each plan includes a&nbsp;number of&nbsp;events and&nbsp;model calculations. Events are pieces of&nbsp;customer behavior data used in&nbsp;your models. The&nbsp;number of&nbsp;machine learning calculations is the&nbsp;number of&nbsp;times you run your models to&nbsp;get calculation results.</p>
			<p>You can sign&nbsp;up for&nbsp;a&nbsp;monthly plan with no&nbsp;commitment, or get two months free by&nbsp;paying annually.</p>
			<p v-if="!current_plan" class="highlight">Each plan includes a&nbsp;14-day free trial, you can cancel anytime.</p>
		</div>
		
		<div class="plans">
			<div class="monthly-annually">
				Monthly
				<Switch :on="annually" @changed="toggleMonthlyAnnually" />
				Annually&nbsp;— <span class="highlight nowrap">2 Months Free</span>
			</div>
			<div class="details">
				<!-- <div class="plan">
					<h3>Free</h3>
					<p class="price">{{ USD.format(0) }} / month</p>
					<p>{{ (new Intl.NumberFormat('en-US')).format(billing_plans.ml.free.model_calculations) }} model calculations</p>
					<p>{{ (new Intl.NumberFormat('en-US')).format(billing_plans.ml.free.events) }} events</p>
					<p>{{ billing_plans.ml.free.data_storage_days }} days of data storage</p>
					<p>Running models on server</p>
				</div> -->
				<div class="plan">
					<h3>Basic</h3>
					<p v-if="annually"><span class="price">{{ USD.format(billing_plans.ml.basic.price_annually / 12) }} / month</span> billed annually</p>
					<p v-else class="price">{{ USD.format(billing_plans.ml.basic.price_monthly) }} / month</p>
					<p>14-day free trial, cancel anytime</p>
					<p>{{ (new Intl.NumberFormat('en-US')).format(billing_plans.ml.basic.model_calculations) }} model calculations</p>
					<p>{{ (new Intl.NumberFormat('en-US')).format(billing_plans.ml.basic.events) }} events</p>
					<p>{{ billing_plans.ml.basic.data_storage_days }} days of data storage</p>
					<!-- <p>Running models on server</p> -->
					
					<div class="select-basic">
						<button v-if="current_plan == 'basic-annually'" class="button" disabled>Current plan</button>
						<button v-else-if="current_plan == 'basic-monthly'" class="button" @click="selectPlan('basic-annually')" :disabled="loading">Switch to Annual Billing</button>
						<button v-else-if="!current_plan" class="button" @click="annually ? selectPlan('basic-annually') : selectPlan('basic-monthly')" :disabled="loading">Start Free Trial</button>
						<button v-else class="button" @click="show_basic_plan_billing_cycles = true" :disabled="loading">Switch to the Basic Plan</button>
					</div>
				</div>
				
				<div class="plan">
					<h3>Standard</h3>
					<p v-if="annually"><span class="price">{{ USD.format(billing_plans.ml.standard.price_annually / 12) }} / month</span> billed annually</p>
					<p v-else class="price">{{ USD.format(billing_plans.ml.standard.price_monthly) }} / month</p>
					<p>14-day free trial, cancel anytime</p>
					<p>{{ (new Intl.NumberFormat('en-US')).format(billing_plans.ml.standard.model_calculations) }} model calculations</p>
					<p>{{ (new Intl.NumberFormat('en-US')).format(billing_plans.ml.standard.events) }} events</p>
					<p>{{ billing_plans.ml.standard.data_storage_days }} days of data storage</p>
					<!-- <p>Running models on server</p> -->
					<!-- <p>Running models in&nbsp;browser</p> -->
					
					<div class="select-standard">
						<button v-if="current_plan == 'standard-annually'" class="button" disabled>Current plan</button>
						<button v-else-if="current_plan == 'standard-monthly'" class="button" @click="selectPlan('standard-annually')" :disabled="loading">Switch to Annual Billing</button>
						<button v-else-if="!current_plan" class="button" @click="annually ? selectPlan('standard-annually') : selectPlan('standard-monthly')" :disabled="loading">Start Free Trial</button>
						<button v-else class="button" @click="show_standard_plan_billing_cycles = true" :disabled="loading">Switch to the Standard Plan</button>
					</div>
				</div>
				
				<div class="plan">
					<h3>Enterprise</h3>
					<p class="price">Volume pricing</p>
					<p>Expert installation</p>
					<p>Choice of hosted / on prem</p>
					<!-- <p>Running models on server</p> -->
					<!-- <p>Running models in&nbsp;browser</p> -->
					<p>Expedited support</p>
					<p>Advanced permissions</p>
					<p>SSO with SAML support</p>
					
					<div class="select-enterprise">
						<button class="button" @click="navigateTo('/support/', { open: { target: '_blank' } })">Contact us</button>
					</div>
				</div>
				
				<!-- <div class="select-free">
					<button class="button" v-if="current_plan && current_plan != 'free'" @click="selectPlan('free')" :disabled="loading">Switch to the Free Plan</button>
					<button class="button" v-else-if="!current_plan" @click="selectPlan('free')" :disabled="loading">Select the Free Plan</button>
					<button class="button" v-else disabled>Current plan</button>
				</div> -->
			</div>
			<div class="additional">
				<p>Additional model calculations: {{ USD.format(3) }} for 1000</p>
				<p>Additional events: {{ USD.format(3) }} for 10,000</p>
			</div>
		</div>
		
		<p>All included usage is on a monthly basis.</p>
		<!-- <p>All included usage is on a monthly basis. The&nbsp;Free and&nbsp;Standard plans include basic email support, the Enterprise plan includes expedited support. Each plan has a&nbsp;number of&nbsp;included events and&nbsp;model calculations. The&nbsp;Free plan is limited to&nbsp;one active web&nbsp;tag (which is enough for&nbsp;most websites).</p> -->
		<p>If you have any questions, you can <nuxt-link to="/support/" target="_blank">send&nbsp;us&nbsp;a&nbsp;message</nuxt-link> or <nuxt-link to="https://almeta.cloud/call" target="_blank">schedule&nbsp;a&nbsp;call</nuxt-link>.</p>
	</div>
	
	<Modal v-if="show_standard_plan_billing_cycles && !selected_plan" @close="show_standard_plan_billing_cycles = false" hide-close-icon="true">
		<template #header>
			<h2>Choose a billing cycle</h2>
			<p>Save 20% on your subscription cost by paying annually.</p>
		</template>
		
		<div class="billing-cycles">
			<div @click="selectPlan('standard-monthly')" :class="{ disabled: loading }">
				<p class="highlight">&nbsp;</p>
				<h3>Monthly</h3>
				<p>{{ USD.format(billing_plans.ml.standard.price_monthly) }} / month</p>
				<p>&nbsp;</p>
				<p class="button-container"><button class="button" @click.stop="selectPlan('standard-monthly')" :disabled="loading">Select Monthly</button></p>
			</div>
			<div @click="selectPlan('standard-annually')" :class="{ disabled: loading }">
				<p class="highlight">Save {{ USD.format(billing_plans.ml.standard.price_monthly * 12 - billing_plans.ml.standard.price_annually) }} per year</p>
				<h3>Annually</h3>
				<p>{{ USD.format(billing_plans.ml.standard.price_annually / 12) }} / month billed annually</p>
				<p>You’ll be charged for the first year</p>
				<p class="button-container"><button class="button" @click.stop="selectPlan('standard-annually')" :disabled="loading">Select Annually</button></p>
			</div>
		</div>
	</Modal>
	
	<Modal v-if="show_basic_plan_billing_cycles && !selected_plan" @close="show_basic_plan_billing_cycles = false" hide-close-icon="true">
		<template #header>
			<h2>Choose a billing cycle</h2>
			<p>Save 29% on your subscription cost by paying annually.</p>
		</template>
		
		<div class="billing-cycles">
			<div @click="selectPlan('basic-monthly')" :class="{ disabled: loading }">
				<p class="highlight">&nbsp;</p>
				<h3>Monthly</h3>
				<p>{{ USD.format(billing_plans.ml.basic.price_monthly) }} / month</p>
				<p>&nbsp;</p>
				<p class="button-container"><button class="button" @click.stop="selectPlan('basic-monthly')" :disabled="loading">Select Monthly</button></p>
			</div>
			<div @click="selectPlan('basic-annually')" :class="{ disabled: loading }">
				<p class="highlight">Save {{ USD.format(billing_plans.ml.basic.price_monthly * 12 - billing_plans.ml.basic.price_annually) }} per year</p>
				<h3>Annually</h3>
				<p>{{ USD.format(billing_plans.ml.basic.price_annually / 12) }} / month billed annually</p>
				<p>You’ll be charged for the first year</p>
				<p class="button-container"><button class="button" @click.stop="selectPlan('basic-annually')" :disabled="loading">Select Annually</button></p>
			</div>
		</div>
	</Modal>
	
	<div v-if="selected_plan == 'free'">
		<h2>Add a payment method</h2>
		<p>You won't be charged yet, this is only used to cover any additional events or model calculations that exceed your plan.</p>
		<AddPaymentMethod plan="free" @close="selected_plan = ''" />
	</div>
	<div v-else-if="selected_plan && selected_plan != 'enterprise'">
		<h2>Add a payment method</h2>
		<p v-if="!current_plan">You won't be charged yet, this is a checkout for a 14-day free trial. You can cancel anytime.</p>
		<AddPaymentMethod :plan="selected_plan" @close="selected_plan = ''" />
	</div>
</div>
</template>

<script lang="ts" setup>
const { $csrfFetch } = useNuxtApp()
const store = useConsoleStore()
const billing_plans = useBillingPlans()

const annually = ref(true)
const current_plan = ref('')
const selected_plan = ref('')
const show_standard_plan_billing_cycles = ref(false)
const show_basic_plan_billing_cycles = ref(false)
const loading = ref(false)

const organization_id = store.organization._id || store.project?.organization || null

const subs = store.organization.billing_settings.subscriptions
if (subs && subs.length)
{
	const subscription = subs.find(sub => sub.service == 'ml' && sub.status == 'active')
	if (subscription)
	{
		if (subscription.plan == 'free')
			current_plan.value = 'free'
		else
			current_plan.value = subscription.plan + '-' + subscription.billing_cycle
	}
}

function toggleMonthlyAnnually(is_annually: boolean)
{
	annually.value = is_annually
}

function selectPlan(plan: string)
{
	if (loading.value)
		return
	
	if (current_plan.value)
		switchPlan(current_plan.value, plan)
	else
		selected_plan.value = plan
}

async function switchPlan(old_plan: string, new_plan: string)
{
	if (old_plan != 'free' && !confirm('Are you sure you want to switch to the ' + getBillingPlanDisplayName(new_plan) + '?'))
		return
	
	loading.value = true
	
	try
	{
		const res = await $csrfFetch('/api/billing/switch-plan/' + organization_id, {
			method: 'POST',
			body: {
				old_plan: old_plan,
				new_plan: new_plan,
			},
		})
		
		if (!res || res.status != 'ok')
			throw new Error('Failed to switch plans')
		
		setTimeout(() => { window.location.reload() }, 2000)
	}
	catch (e)
	{
		console.error(e)
		alert('Error: failed to switch plans. Please try again later.')
		loading.value = false
	}
}
</script>

<style lang="scss" scoped>
.loading {
	cursor: wait;
}

.pricing {
	max-width: 1260px;
	margin: 0 auto;
	line-height: 1.5;

	@media (max-width: 1020px) {
		padding: 40px;
	}

	@media (max-width: $breakpoint-phone) {
		padding: 20px;
	}
}

.plans {
	margin: 0 auto 20px auto;
	padding: 20px;
	max-width: 960px;

	@media (max-width: 1020px) {
		margin: 30px auto;
	}
	
	@media (max-width: 600px) {
		padding: 0;
	}

	.monthly-annually {
		font-weight: 400;
		padding-bottom: 10px;
		border-bottom: solid 1px $light-border;

		@media (max-width: 500px) {
			/* padding-left: 20px; */
		}

		.dark-mode & {
			color: #fff;
		}

		.highlight {
			color: #379200;

			.dark-mode & {
				color: #62ff00;
			}
		}
	}

	.details {
		// display: flex;
		// justify-content: space-between;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 10px 50px;
		padding: 40px 0 35px 0;
		margin-bottom: 20px;
		border-bottom: solid 1px $light-border;

		@media (max-width: 1020px) {
			padding: 20px 0;
		}

		/* @media (max-width: 600px) {
			gap: 20px;
		} */

		@media (max-width: 800px) {
			gap: 10px 0;
			grid-template-columns: 100%;
		}
		
		.plan {
			display: flex;
			flex-direction: column;
		}

		.select-basic, .select-standard, .select-enterprise {
			margin-top: auto;
			padding-top: 10px;

			@media (max-width: 800px) {
				margin-bottom: 40px;
			}
		}

		h3 {
			font-size: 28px;
			font-weight: bold;
			margin: 0 0 10px 0;

			@media (max-width: 1020px) {
				font-size: 24px;
			}

			@media (max-width: $breakpoint-phone) {
				font-size: 20px;
			}
		}

		.price {
			font-weight: bold;
		}
	}

	.additional p {
		margin-bottom: 5px;
	}
}

.highlight {
	font-weight: 500;
}

.billing-cycles {
	display: flex;
	gap: 20px;

	@media (max-width: $breakpoint-phone) {
		flex-wrap: wrap;
	}
	
	& > div {
		flex: 1 1 0;
		text-align: center;
		border: solid 1px #979797;
		border-radius: 7px;
		padding: 20px;
		cursor: pointer;
		
		&:hover {
			background: #f5f5f5;
			
			.dark-mode & {
				background: #444;
			}
		}
		
		&.disabled {
			cursor: wait;
			&:hover {
				background: none;
			}
		}
		
		h3 {
			font-size: 28px;
			margin: 0 0 10px 0;
		}
		
		p {
			/* margin: 0; */
		}
		
		.highlight {
			margin-top: 10px;
			color: #379200;

			.dark-mode & {
				color: #62ff00;
			}
		}
		
		.button-container {
			margin: 30px;
		}
	}
}
</style>