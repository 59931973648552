<template>
<span :class="{ switch: true, on: state }" @click="toggle">
	<span>&nbsp;</span>
</span>
</template>

<script lang="ts" setup>
const props = defineProps<{
	on?: Boolean
}>()

const emit = defineEmits(['changed'])

let state = ref(false)
if (props.on)
	state.value = true

function toggle()
{
	state.value = !state.value
	emit('changed', state.value)
}
</script>

<style lang="scss" scoped>
.switch {
	display: inline-block;
	position: relative;
	top: 2px;
	margin: 0 5px;
	background: #626262;
	width: 48px;
	height: 21px;
	padding: 3px;
	border-radius: 14px;
	box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.5);
	cursor: pointer;
	user-select: none;
	transition: background .15s;

	& > span {
		display: inline-block;
		position: relative;
		background: #fff;
		width: 21px;
		height: 21px;
		border-radius: 100%;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
		transition: all .15s;
	}

	&.on {
		background: #379200;
		
		.dark-mode & {
			background: #3d0;
		}

		& > span {
			transform: translateX(27px);
		}
	}
}
</style>